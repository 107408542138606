import { tns } from "tiny-slider/src/tiny-slider";
import LazyLoad from "vanilla-lazyload";
import init from "./common";

init();

// Init Lazy load instance
var lazyLoad = new LazyLoad();
function sliderLazy() {
  lazyLoad.update();
}

if (jQuery(".slider-je-suis").length > 0) {
  var slider_je_suis = tns({
    container: ".slider-je-suis",
    items: 6,
    loop: true,
    autoplay: true,
    autoplayButtonOutput: false,
    controls: true,
    controlsContainer: ".controls-slider-je-suis",
    autoHeight: false,
    nav: false,
    gutter: 0,
    autoplayTimeout: 7000,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },
      900: {
        items: 6,
      },
    },
  });
  slider_je_suis.events.on("transitionEnd", sliderLazy);
}

// carte
// jQuery("#carte-home path").on("mouseenter", function () {
//   console.log("hover");
//   jQuery("#info-box").css("display", "flex");
//   jQuery("#info-box").html(jQuery(this).data("commune"));
// });
// jQuery("#carte-home path").on("mouseleave", function () {
//   jQuery("#info-box").css("display", "none");
// });
// jQuery(document)
//   .on("mousemove", function (e) {
//     jQuery("#info-box").css("top", e.pageY - 70);
//     jQuery("#info-box").css("left", e.pageX - 10);
//   })
//   .mouseover();
